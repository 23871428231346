import Vue from "vue";
import App from "./App.vue";
import router from "./routers.js";
import store from "./store";
import axios from "axios";
import { Message } from "element-ui";

import "./assets/iconfont/index.css";
import "./assets/css/global.css";
import "./assets/iconfont/index.js";
import filters from './util/filter';
// import Vant from 'vant';


// const encryp = require("./util/encryp");

Vue.prototype.$ELEMENT = { size: "small" };
Vue.config.productionTip = false;
// 全局声明过滤器
for (var key in filters) {
  Vue.filter(key, filters[key])
}

axios.interceptors.response.use(
  (response) => {
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
    // 否则的话抛出错误
    return response;
  },
  (error) => {
    if (error?.response?.status) {
      switch (error.response.status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 401:
          Message.error(error.response?.data?.data);
          break;
        // case 403:
        //   // 清除token
        //   break;
        case 500:
          Message.error(error.response?.data?.data);
          break;
        // 其他错误，直接抛出错误提示
        default:
          Message.error(error.response?.data?.data);
      }
      return Promise.reject(error.response);
    } else {
      alert("网络错误，请联系作者");
    }

  }
);

Vue.prototype.$axios = axios;
// Vue.prototype.$encryp = encryp;

// Vue.use(Vant);

// Vue.use(vant.Lazyload);

// 判断是否为手机端
Vue.prototype.$isPhone = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
