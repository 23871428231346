/**
 * 工具类
 */
import CryptoJS from 'crypto-js'
let keyStr = "api/fetch_data"

//加密
export function encrypt(word) {
  var encrypted = CryptoJS.AES.encrypt(word, keyStr);
  return encrypted.toString();
}

//解密
// export function decrypt(word, keyStr){  
export function decrypt(word) {
  var bytes  = CryptoJS.AES.decrypt(word, keyStr);
  return bytes.toString(CryptoJS.enc.Utf8);
}