import Vue from "vue";
import Vuex from "vuex";
import { decrypt } from "../util/encryp";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // 全局存储用户信息
    userInfo: null,
    // 用户会员状态：0-普通用户、1-会员用户
    isVip: 0,
  },
  mutations: {
    // 更新用户信息
    updateUserInfo(state, obj) {
      state.userInfo = obj;
    },
    // 修改用户会员状态
    setVip(state, level) {
      state.isVip = Number(level);
    }
  },
  actions: {
    // 调用更新用户信息及会员状态
    updateUser({ commit }) {
      let user_info = null;
      let user_vip = 0;
      if (window.localStorage.userInfo) {
        user_info = JSON.parse(
          decrypt(window.localStorage.userInfo)
        );
        const { vip_level, vip_end_time }  = user_info;
        const nowTime = new Date().getTime();
        if (vip_level === 1 && vip_end_time > nowTime) {
          user_vip = 1;
        }
      }
      commit("updateUserInfo", user_info);
      commit("setVip", user_vip);
    },
  },
  modules: {},
});
