import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const router = new Router({
  // mode: 'history',
  routes: [
    {
      path: "/",
      redirect: {
        name: "container"
      },
    },
    {
      path: "/stock/chart/all",
      name: "日线图",
      component: () => import("./views/stock/chart/all.vue"),
      meta: {
        title: "数据中心-股票基金助手"
      }
    },
    {
      path: "/stock/chart/day",
      name: "日线图",
      component: () => import("./views/stock/chart/day.vue"),
      meta: {
        title: "数据中心-股票基金助手"
      }
    },
    {
      path: "/stock/chart/k",
      name: "k线图",
      component: () => import("./views/stock/chart/k.vue"),
      meta: {
        title: "数据中心-股票基金助手"
      }
    },
    {
      path: "/market/capital_flow",
      name: "capital_flow",
      component: () => import("./views/market/capital_flow.vue")
    },
    {
      path: "/market/plate_flow",
      name: "plate_flow",
      component: () => import("./views/market/plate_flow.vue")
    },
    {
      path: "/market/north_flow",
      name: "north_flow",
      component: () => import("./views/market/north_flow.vue")
    }
  ]
});

router.beforeEach((to, from, next) => {
  //判断是否有标题
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = "股票基金助手-其他工具"
  }
  //执行进入路由，如果不写就不会进入目标页
  next();
})

export default router;
