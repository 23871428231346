<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      isLegitimate: true,
    };
  },
};
</script>

<style type="text/css">
html {
  height: 100%;
}
body {
  padding: 0;
  margin: 0;
  height: 100%;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: rgba(240, 240, 240, 1);
}
.up {
  color: #ff4d4f;
}
.down {
  color: #52c41a;
}
</style>
