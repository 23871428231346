// 除以一百
function CYB (val){
  return (val / 100).toFixed(2);
}
// 除以一千
function CYQ (val){
  return (val / 1000).toFixed(2);
}
// 保留小数点两位
function TwoDecimal (val){
  return Number(val).toFixed(2);
}
// 净额单位转换
function NETTO (val) {
  const num = Number(val);
  if (num >= 100000000) {
    return (num / 100000000).toFixed(2) + '亿';
  } else {
    return (num / 10000).toFixed(2) + '万';
  }
}

export default({
  CYB,
  CYQ,
  TwoDecimal,
  NETTO
});